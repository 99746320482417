"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Cookies from "js-cookie";
import { usePathname, useRouter } from "next/navigation";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import {
  Button,
  Divider,
  ImageListItem,
  Link,
  List,
  ListItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";

import styles from "../app/page.module.css";
import useDebounce from "@/utils/debounce";
import useWebsiteInfoStore from "@/utils/store/WebsiteInfoStore";
import { TenantCustomDomainAPI } from "@/lib/CustomDomainAPI";

const navbardata = [
  { title: "Home", Link: "/" },
  { title: "Courses", Link: "/courses" },
  { title: "About Us", Link: "/about" },
  { title: "Blog", Link: "/blog" },
  { title: "Events", Link: "/events" },
  { title: "Contact", Link: "/contact" },
];
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  flexGrow: 1,
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#363030AD",
  height: "35.12px",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    border: "0.5px solid #000000",
    borderRadius: "5px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "20ch",
    },
  },
}));
export default function Navbar() {
  const { websiteInfoData, initWebsiteInfoStore } = useWebsiteInfoStore();
  const matches = useMediaQuery("(max-width:1080px)");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hostname, setHostname] = useState();
  const [search, setSearch] = useState();
  const [searchHide, setSearchHide] = useState(false);
  const [origin, setOrigin] = useState();
  const router = useRouter();
  const currentPage = usePathname();
  const debouncedSearchTerm = useDebounce(search, 500);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // if (e.key === "Enter") {
    //   router.push(`/courses?search=${e.target.value}`);
    // } else {
    // }
  };

  const fetchTenantName = async () => {
    const res = await TenantCustomDomainAPI(window.location.hostname);
    Cookies.set("custom_tenant", res.response.tenantName, {
      path: "/",
      domain: res.response.customDomain,
      // domain: "localhost",
      secure: true,
      sameSite: "Strict",
    });
  };
  useEffect(() => {
    currentPage && currentPage?.includes("/courses")
      ? setSearchHide(true)
      : setSearchHide(false);
  }, [currentPage]);

  useEffect(() => {
    search && router.push(`/courses?search=${search}`);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const customTenant = Cookies.get("custom_tenant");

    if (
      !customTenant &&
      !(
        window.location.hostname.includes("www.ebrainee.com") ||
        window.location.hostname.includes("www.e-brainee.com") ||
        window.location.hostname.includes("www.localhost")
      )
    ) {
      fetchTenantName();
    }
    initWebsiteInfoStore();

    if (typeof window !== "undefined") {
      setHostname(window.location.hostname);
      setOrigin(window.location.origin);
    }
  }, []);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "start",
        background: "#fff",
        width: "100%",
        color: "#000",
      }}>
      <Divider />
      {searchHide && (
        <Box
          sx={{
            padding: "20px 0px 0px 20px",
          }}>
          <Search>
            <SearchIconWrapper>
              <img src="/assets/Icon/SearchIcon.svg" alt="SearchIcon" />
            </SearchIconWrapper>

            <StyledInputBase
              sx={{ backgroundColor: "transparent" }}
              placeholder="Want to learn?"
              inputProps={{ "aria-label": "search" }}
              type="text"
              name="searchData"
              className="input-searchbar"
              onChange={handleSearch}
            />
          </Search>
        </Box>
      )}
      <List>
        {currentPage &&
          navbardata.map((item, index) => (
            <ListItem key={index} sx={{ background: "#fff" }}>
             
     
              <Link
                href={item.Link}
                sx={
                  ( item?.title=="Home" ? (currentPage==item?.Link ):( item?.Link!="/" &&   currentPage.includes(item?.Link)))

                    ? {
                        color: "#DB2BBB",
                        padding: "0px 20px",
                        textDecoration: "none",
                        fontWeight: 500,
                      }
                    : {
                        color: "#000",
                        padding: "0px 20px",
                        textDecoration: "none",
                        fontWeight: 500,
                        "&:hover": { color: "#DB2BBB" },
                      }
                }>
                {item.title}
              </Link>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 10,
      }}>
      <AppBar
        sx={{
          backgroundColor: "white",
          height: { lg: "104.81px", xs: "80px" },
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
        position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: { lg: "104.81px", xs: "80px" },
            flexGrow: 1,
          }}>
          <ul>
            <ImageListItem
              sx={{
                cursor: "pointer",
                marginLeft: { lg: "30px", xs: "0px" },
                marginRight: { lg: "30px", xs: "0px" },
              }}>
              <Link href={origin}>
                {websiteInfoData ? (
                  <Image
                    src={websiteInfoData?.websiteLogoUrl}
                    className={styles.headerLogo}
                    alt="logo"
                    loading="lazy"
                    width={100}
                    height={100}
                  />
                ) : (
                  <Image
                    src="/assets/Logo/Logo.svg"
                    className={styles.headerLogo}
                    alt="logo"
                    loading="lazy"
                    width={100}
                    height={100}
                  />
                )}
              </Link>
            </ImageListItem>
          </ul>

          {!matches && (
            <Box>
              {searchHide && (
                <Search>
                  <SearchIconWrapper>
                    <img src="/assets/Icon/SearchIcon.svg" alt="SearchIcon" />
                  </SearchIconWrapper>

                  <StyledInputBase
                    sx={{ backgroundColor: "transparent" }}
                    placeholder="Want to learn?"
                    inputProps={{ "aria-label": "search" }}
                    type="text"
                    name="searchData"
                    className="input-searchbar"
                    onChange={handleSearch}
                  />
                </Search>
              )}
            </Box>
          )}
          {!matches && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "50px",
                justifyContent: "center",
                flexGrow: 1,
                cursor: "pointer",
              }}>
              {currentPage &&
                navbardata.map((value, index) => (
                  <Link
                    href={value.Link}
                    key={index}
                    sx={
                     ( value.title=="Home" ? (currentPage==value?.Link ):( value?.Link!="/" &&   currentPage.includes(value?.Link)))
                        ? {
                            color: "#DB2BBB",
                            fontSize: "16px",
                            fontWeight: "400",
                            textDecoration: "none",
                            "&:hover": { color: "#DB2BBB" },
                          }
                        : {
                            color: "#3A3A3A",
                            fontSize: "16px",
                            fontWeight: "400",
                            textDecoration: "none",
                            "&:hover": { color: "#DB2BBB" },
                          }
                    }>
                    {value.title}
                  </Link>
                ))}
            </Box>
          )}

          {hostname && (
            <Box>
              {!(
                hostname?.includes("www.ebrainee.com") ||
                hostname?.includes("www.e-brainee.com") ||
                hostname?.includes("www.localhost")
              ) ? (
                <Box
                  sx={{
                    display: { xs: "flex", lg: "flex" },
                    flexGrow: 0.1,
                    paddingLeft: "90px",
                  }}>
                  <Button
                    sx={{
                      backgroundColor: "transparent",
                      marginRight: "20px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}>
                    <Link
                      // href="https://www.app.ebrainee.com/login"
                      href={`https://${hostname?.split(".")[0]}.app.${
                        hostname?.split(".")[1]
                      }.${ hostname?.substring(hostname?.indexOf(hostname?.split(".")[1])+hostname?.split(".")[1]?.length+1)}/login`}
                      sx={{
                        color: "#3A3A3A",
                        fontSize: "16px",
                        fontWeight: "500",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}>
                      Login
                    </Link>
                  </Button>
                  <Button>
                    <Link
                      // href="https://app.ebrainee.com/signup"
                      href={`https://${hostname?.split(".")[0]}.app.${
                        hostname?.split(".")[1]
                      }.${hostname?.substring(hostname?.indexOf(hostname?.split(".")[1])+hostname?.split(".")[1]?.length+1)}/signup`}
                      sx={{
                        color: "#3A3A3A",
                        fontSize: "16px",
                        fontWeight: "500",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#fff",
                      }}>
                      Signup
                    </Link>
                  </Button>
                </Box>
              ) : (
                // registration
                <Box
                  sx={{
                    display: { xs: "flex", lg: "flex" },
                    flexGrow: 0.1,
                    paddingLeft: "90px",
                  }}>
                  <Button
                    sx={{
                      backgroundColor: "#DB2BBB",
                      marginRight: "20px",
                      "&:hover": {
                        backgroundColor: "#DB2BBB",
                      },
                    }}>
                    <Link
                      href="/subscription-plan"
                      sx={{
                        color: "#ffff",
                        fontSize: "16px",
                        fontWeight: "500",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}>
                      Registration
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          )}

          {matches && (
            <IconButton
              color="#000"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>
          )}

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <MenuIcon />
          </Box>
        </Toolbar>
        {matches && (
          <Box>
            {mobileOpen && (
              <Box
                component="nav"
                sx={{ position: "relative", zIndex: 10, width: "100%" }}>
                {drawer}
              </Box>
            )}
          </Box>
        )}
      </AppBar>
    </Box>
  );
}
